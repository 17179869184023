// for browser

// import 'core-js/es6';
// just import what are you using
//import 'core-js/fn/symbol'
//import 'core-js/fn/object'
//import 'core-js/fn/function'
//import 'core-js/fn/parse-int'
//import 'core-js/fn/parse-float'
//import 'core-js/fn/number'
//import 'core-js/fn/math'
//import 'core-js/fn/string'
//import 'core-js/fn/date'
//import 'core-js/fn/regexp'
//import 'core-js/fn/map'
//import 'core-js/fn/set'
//import 'core-js/fn/weak-map'
//import 'core-js/fn/weak-set'
//import 'core-js/fn/typed'
//import 'core-js/fn/reflect'
//import 'core-js/es7/reflect'

//TODO:update core-js
//
import 'core-js/fn/number/is-nan'
import 'core-js/fn/string/ends-with'
import 'core-js/fn/string/includes'
import 'core-js/fn/string/starts-with'
import 'core-js/fn/array'
import 'core-js/fn/promise'
